import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

import * as Constants from "../constants";

const cachebusterTstp = process.env.NODE_ENV !== "production" ? Date.now() : process.env.REACT_APP_BUILD_HASH;
const cachebuster = `?tstp=${cachebusterTstp}`;

// https://phrase.com/blog/posts/localizing-react-apps-with-i18next/

i18next
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: localStorage.getItem("lang") || "en_US",
        supportedLngs: ["en_US", "fr_FR"],
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: Constants.API_BASE_URL.concat(`/labels/{{lng}}${cachebuster}`),
        },
    });
export default i18next;
