import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { Trans, useTranslation } from "react-i18next";

import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { Typography } from "@mui/material";
import modules from "../modules";

const item = {
    py: "2px",
    px: 3,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover, &:focus": {
        bgcolor: "rgba(255, 255, 255, 0.08)",
    },
};

const itemCategory = {
    boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
    py: 1.5,
    px: 3,
};

export default function Sidebar(props) {
    const { ...other } = props;
    const { t } = useTranslation();

    const userRights = useSelector((state) => state.auth.rights);

    const location = useLocation();

    const renderCategory = (id) => {
        <ListItem sx={{ py: 2, px: 3 }}>
            <ListItemText sx={{ color: "#fff" }}>
                <Trans i18nKey={`${id}`} />
            </ListItemText>
        </ListItem>;
    };

    const renderModules = (id, children) => {
        // renders the category if the user has at least the rights for at least one module
        const hasRight = children.find((module) => userRights.includes(module.right));
        if (hasRight) {
            const modulesList = children.map(({ id: childId, path, icon, right }) =>
                userRights.includes(right) ? (
                    <ListItem disablePadding key={childId} sx={{ typography: "title" }}>
                        <ListItemButton selected={location.pathname.substring(0, path.length) === path} sx={item} component={Link} to={path}>
                            <ListItemIcon sx={{ pr: 1 }}>{icon}</ListItemIcon>
                            <ListItemText>
                                <Typography variant="title">{t(`${childId}`)}</Typography>
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                ) : (
                    ""
                )
            );
            return (
                <Box key={id}>
                    {renderCategory(id)}
                    {modulesList}
                    <Divider sx={{ mt: 2 }} />
                </Box>
            );
        }
        return null;
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem
                    component={Link}
                    to="/"
                    sx={{
                        ...item,
                        ...itemCategory,
                        fontSize: 25,
                        backgroundColor: "#fff!important",
                    }}
                >
                    <Typography variant="title" color="primary">
                        {t("app_name")}
                    </Typography>
                </ListItem>
                <ListItem component={Link} to="/" sx={{ ...item, ...itemCategory, typography: "title" }}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant="title">{t("home")}</Typography>
                    </ListItemText>
                </ListItem>
                {modules.map(({ id, children }) => renderModules(id, children))}
            </List>
        </Drawer>
    );
}
