import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Logout from "@mui/icons-material/Logout";

import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
// import { useGoogleLogout } from "react-google-login";
import { googleLogout } from "@react-oauth/google";
import { clearToken, setLocale } from "../slices/auth";

import * as Constants from "../constants";

const clientId = Constants.GOOGLE_CLIENT_ID;

function Header(props) {
    const { onDrawerToggle } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const auth = useSelector((state) => state.auth);

    // user menu for logout / options
    const [anchorUserEl, setAnchorUserEl] = React.useState(null);
    const isOpenUserMenu = Boolean(anchorUserEl);

    const handleUserMenuClick = (event) => {
        setAnchorUserEl(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setAnchorUserEl(null);
    };

    // language menu
    const [anchorLanguageMenuEl, setAnchorLanguageMenuEl] = React.useState(null);
    const isOpenLanguageMenu = Boolean(anchorLanguageMenuEl);
    const handleLanguageMenuClick = (event) => {
        setAnchorLanguageMenuEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setAnchorLanguageMenuEl(null);
    };
    const changeLanguageTo = (newLocale) => {
        i18n.changeLanguage(newLocale);
        dispatch(setLocale(newLocale));
    };

    // user menu
    const onLogoutSuccess = () => {
        // lancer action SIGN_OUT
        dispatch(clearToken());
        navigate("/login");
    };

    const onLogoutFailure = () => {
        console.log("Google logout failed");
    };

    const signOut = () => {
        googleLogout();
        dispatch(clearToken());
        navigate("/login");
    };

    const { username, avatar } = auth;

    return (
        <AppBar color="" position="sticky" elevation={5}>
            <Toolbar>
                <Grid container spacing={1} alignItems="center">
                    <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
                        <IconButton color="inherit" aria-label="open menu" onClick={onDrawerToggle} edge="start">
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Grid item sx={{ display: "flex" }}>
                        <Typography variant="title" color="primary" sx={{ fontSize: 25, padding: "12px", color: "#FFF" }}>
                            &nbsp;
                        </Typography>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <Button id="language-button" aria-controls={isOpenLanguageMenu ? "language-menu" : undefined} aria-haspopup="true" aria-expanded={isOpenLanguageMenu ? "true" : undefined} onClick={handleLanguageMenuClick}>
                            <LanguageIcon color="disabled" />
                        </Button>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleUserMenuClick} sx={{ p: 0.5 }}>
                            <Avatar alt={username} aria-controls={isOpenUserMenu ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={isOpenUserMenu ? "true" : undefined}>
                                <img width="33" height="33" alt="" src={avatar} referrerPolicy="no-referrer" />
                            </Avatar>
                        </IconButton>
                    </Grid>
                </Grid>
                <Menu
                    id="language-menu"
                    anchorEl={anchorLanguageMenuEl}
                    open={isOpenLanguageMenu}
                    onClose={handleLanguageMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <MenuItem
                        selected={i18n.language === "fr_FR"}
                        onClick={() => {
                            handleLanguageMenuClose();
                            changeLanguageTo("fr_FR");
                            localStorage.setItem("lang", "fr_FR");
                        }}
                    >
                        {t("fr")}
                    </MenuItem>
                    <MenuItem
                        selected={i18n.language === "en_US"}
                        onClick={() => {
                            handleLanguageMenuClose();
                            changeLanguageTo("en_US");
                            localStorage.setItem("lang", "en_US");
                        }}
                    >
                        {t("en")}
                    </MenuItem>
                </Menu>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorUserEl}
                    open={isOpenUserMenu}
                    onClose={handleUserMenuClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    sx={{
                        "& .MuiPaper-root": {},
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleUserMenuClose();
                            signOut();
                        }}
                    >
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        {t("logout")}
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
