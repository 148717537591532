import React from "react";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import HouseIcon from "@mui/icons-material/House";
import StorefrontIcon from "@mui/icons-material/Storefront";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import PersonIcon from "@mui/icons-material/Person";
import { Screenshot } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CodeIcon from "@mui/icons-material/Code";
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";

const MODULES = [
    {
        id: "main",
        children: [
            {
                id: "screenshots",
                icon: <Screenshot />,
                path: "/screenshots",
                right: "access_screenshots",
                children: [],
            },
            {
                id: "wrapper",
                path: "/wrapper",
                icon: <CodeIcon />,
                right: "access_wrapper_adsconfig",
                children: [
                    {
                        id: "wrapper_adsconfig",
                        icon: <AdUnitsIcon />,
                        path: "/wrapper/adunitconfig",
                        right: "access_wrapper_adsconfig",
                    },
                    {
                        id: "wrapper_networkconfig",
                        icon: <HouseIcon />,
                        path: "/wrapper/networkconfig",
                        right: "access_wrapper_networkconfig",
                    },
                    {
                        id: "wrapper_configurationsite",
                        icon: <StorefrontIcon />,
                        path: "/wrapper/siteconfig",
                        right: "access_wrapper_configurationsite",
                    },
                    {
                        id: "dashboard_config",
                        path: "/wrapper/dashboard",
                        icon: <SpaceDashboardIcon />,
                        right: "sites",
                    },
                    {
                        id: "wrapper_renderingsite",
                        path: "/wrapper/renderingsite",
                        icon: <FormatPaintIcon />,
                        right: "access_wrapper_renderingsite",
                    },
                ],
            },
            {
                id: "tools",
                path: "/tools",
                icon: <BuildIcon />,
                right: "access_tools",
                children: [],
            },
            {
                id: "administration",
                path: "/administration",
                icon: <SettingsIcon />,
                right: "access_user",
                children: [
                    {
                        id: "users",
                        path: "/administration/users",
                        icon: <PersonIcon />,
                        right: "access_user",
                    },
                ],
            },
        ],
    },
];

export default MODULES;
