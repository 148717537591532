import { Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BlockLink = ({ text, icon, to, dimensions, requiredPermission }) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ width: dimensions.x, height: dimensions.y, margin: 1 }} component={Link} to={to} className="blocklink">
            <CardContent>
                {icon}
                <Typography>{text}</Typography>
            </CardContent>
        </Card>
    );
};
export default BlockLink;
