import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import jwtDecode from "jwt-decode";
import { Button, CssBaseline, Grid, Link } from "@mui/material";
import { login, setLocale, setAvatar, clearToken, resetUsername, setApiToken } from "../slices/auth";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://webedia-group.com/">
                Webedia
            </Link>{" "}
            {new Date().getFullYear()}
        </Typography>
    );
}

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { i18n, t } = useTranslation();
    const apiToken = useSelector((state) => state.auth.token);

    function redirect() {
        if (location.state === null) {
            navigate("/");
        } else if (location.state.from !== location.pathname && location.state.from !== undefined) {
            navigate(location.state.from);
        } else {
            navigate("/");
        }
    }

    const askLogin = () => {
        window.location.href =
            "mailto:adserver@webedia-group.com?subject=Demande d'accès au Business Board&body=Bonjour,%0D%0A %0D%0A Je souhaiterai avoir accès au Business Board. %0D%0A %0D%0A [Se présenter]. %0D%0A %0D%0A [Indiquer votre poste, BU, nom de votre responsable hiérarchique]. %0D%0A %0D%0A %0D%0A Cordialement,";
    };

    useLayoutEffect(() => {
        document.body.style.backgroundImage = "url(/home.jpg)";
        return () => {
            document.body.style.backgroundImage = "none";
        };
    });

    const onGoogleLoginSuccess = async (googleRes) => {
        const jwt = jwtDecode(googleRes.credential);
        dispatch(setAvatar(jwt.picture));
        dispatch(login({ token: googleRes.credential, isGoogle: true }))
            .unwrap()
            .then((userData) => {
                dispatch(setApiToken(userData.token));
                dispatch(setLocale(userData.language.shortname));
                i18n.changeLanguage(userData.language.shortname);
                redirect();
            })
            .catch((error) => {
                toast.warning("login failed");
                if (error.code === "ECONNABORTED") {
                    toast.warning("Timeout error, check your internet and or vpn connection.");
                } else if (error.response.status === 401) {
                    navigate("/login");
                } else {
                    toast.warning("Unauthorized");
                }
            });
    };

    useEffect(() => {
        if (apiToken) {
            dispatch(login({ token: apiToken, isGoogle: false }))
                .unwrap()
                .then((userData) => {
                    dispatch(setApiToken(userData.token));
                    dispatch(setAvatar(window.localStorage.getItem("avatar")));
                    dispatch(setLocale(userData.language.shortname));
                    i18n.changeLanguage(userData.language.shortname);
                    redirect();
                })
                .catch((er) => {
                    if (parseInt(er.message, 10) === 401) {
                        dispatch(clearToken());
                        dispatch(resetUsername());
                        navigate("/login");
                    }
                    toast.warning("login failed");
                });
        }
    }, [apiToken]);

    return (
        <div>
            {!apiToken && (
                <>
                    <Box sx={{ margin: 1, top: 0, right: 0, position: "fixed" }}>
                        <img src="/Webedia-Secondary-Logo-Blue.svg" width="100%" alt="webedia" />
                    </Box>
                    <Container component="main" maxWidth="md">
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 15,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5" align="center">
                                {t("login_welcome")}
                            </Typography>
                            <Box sx={{ mt: 3 }}>
                                <GoogleLogin
                                    onSuccess={onGoogleLoginSuccess}
                                    onError={(res) => {
                                        console.log("Login Failed", res);
                                    }}
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <Grid container>
                                    <Grid item>
                                        <Button variant="body2" onClick={askLogin}>
                                            {t("login_ask_access")}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Copyright />
                            </Box>
                        </Box>
                    </Container>
                </>
            )}
        </div>
    );
};

export default Login;
