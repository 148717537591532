import { createSlice, createAsyncThunk, getDefaultMiddleware } from "@reduxjs/toolkit";
import axios from "axios";
import * as Constants from "../constants";

const oAuth = (data) => axios({ url: Constants.API_BASE_URL.concat("/oauth"), method: "POST", data });
const init = () => axios({ url: Constants.API_BASE_URL.concat("/init"), method: "GET" });

export const login = createAsyncThunk("auth/login", async (data) => {
    try {
        let response;
        if (data.isGoogle) {
            response = await oAuth(data);
        } else {
            axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
            response = await init();
            /* handle expired token */
            if (response.status === 200 && !response.data.success) {
                return Promise.reject(new Error(401));
            }
        }

        return response.data.user;
    } catch (error) {
        const code = error.code === "ECONNABORTED" ? "ECONNABORTED" : error.response.status;
        return Promise.reject(new Error(code));
    }
});

const initialState = {
    token: window.localStorage.getItem("api_token"),
    locale: "fr-FR",
    username: null,
    avatar: null,
    rights: [],
    acls: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
    reducers: {
        clearToken: (state) => {
            state.token = null;
            axios.defaults.headers.common.Authorization = "";
            window.localStorage.removeItem("api_token");
        },
        setApiToken: (state, action) => {
            if (action.payload !== state.token) {
                state.token = action.payload;
            }

            axios.defaults.headers.common.Authorization = `Bearer ${action.payload}`;
            window.localStorage.setItem("api_token", action.payload);
        },
        setAvatar: (state, action) => {
            if (action.payload) {
                state.avatar = action.payload;
                window.localStorage.setItem("avatar", action.payload);
            } else {
                state.avatar = "/Webedia-Monogram.svg";
            }
        },
        setLocale: (state, action) => {
            state.locale = action.payload;
        },
        resetUsername: (state, action) => {
            state.username = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.username = `${action.payload.firstname} ${action.payload.lastname}`;
                state.rights = Array.from(new Set(action.payload.rights.map((item) => item.name)));
                state.acls = action.payload.rights;
            })
            .addCase(login.rejected, (state) => {
                state.username = null;
            });
    },
});
export const { clearToken, setAvatar, setCustomToken, setApiToken, setLocale, resetUsername } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;
