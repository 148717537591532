import axios from "axios";
import { toast } from "react-toastify";

import * as Constants from "./constants";
import { clearToken, resetUsername } from "./slices/auth";

axios.defaults.baseURL = Constants.API_BASE_URL;
axios.defaults.timeout = 30000;
axios.defaults.headers.common = {
    "Content-Type": "application/json",
};

const request = async function (options, api) {
    const onSuccess = function (response) {
        return response;
    };

    const onError = function (error) {
        console.error("Request Failed:", error.config, error.response, typeof error);

        // si erreur 401, en envoyant api en second param de la function : async function(options, api)
        // api.dispatch(loginblabla...)

        if (error.code === "ECONNABORTED") {
            toast.warning("Timeout error, check your internet and or vpn connection.");
        } else if (error.response.status === 403) {
            toast.warning("Unauthorized");
        } else if (error.response.status === 401) {
            if (error.response && error.response.data) {
                toast.warning(error.response.data);
            } else {
                toast.warning("Unauthorized");
            }
            api.dispatch(clearToken());
            api.dispatch(resetUsername());
        } else {
            // eslint-disable-next-line no-lonely-if
            if (error.response && error.response.data) {
                Object.keys(error.response.data).map((keyError) => error.response.data[keyError].map((rowError) => toast.error(`${keyError}: ${rowError}`, { autoClose: 10000 })));
            } else {
                console.error("Error Message:", error.message);
            }
        }

        return Promise.reject(error.response || error.message);
    };

    return axios(options).then(onSuccess).catch(onError);
};

export default request;
