import React from "react";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import ModuleNav from "./ModuleNav";

import modules from "../modules";
import BlockLink from "./BlockLink";

const SubModuleNav = () => {
    const route = useLocation();
    const { t } = useTranslation();

    return (
        <>
            {modules[0].children.filter((item) => item.id === route.pathname.replace("/", "")).length > 0 && (
                <ModuleNav title={modules[0].children.filter((item) => item.id === route.pathname.replace("/", ""))[0].id} tabs={[]} icon={modules[0].children.filter((item) => item.id === route.pathname.replace("/", ""))[0].icon} />
            )}
            <Typography>{modules[0].children.filter((item) => item.id === route.pathname.replace("/", "")).id}</Typography>
            <Box display="flex" sx={{ justifyContent: "center" }}>
                {modules[0].children.filter((item) => item.id === route.pathname.replace("/", "")).length > 0 &&
                    modules[0].children
                        .filter((item) => item.id === route.pathname.replace("/", ""))[0]
                        .children.map((item) => <BlockLink text={t(item.id)} icon={item.icon} to={item.path} dimensions={{ x: 150, y: 100 }} requiredPermission={item.right} />)}
            </Box>
        </>
    );
};

export default SubModuleNav;
