import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import "./App.css";

import { ToastContainer } from "react-toastify";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as locales from "@mui/material/locale";

import theme from "../theme";
import RIGHTS from "../rights";
import RequireAuth from "../helpers/RequireAuth";
import RequireRight from "../helpers/RequireRight";

import MainLayout from "./MainLayout";
import NotFound from "./NotFound";
import Login from "./Login";
import Spinner from "./Spinner";
import SubModuleNav from "./SubModuleNav";
import UsersList from "./Users/UsersList";

const App = () => {
    const locale = useSelector((state) => state.auth.locale);

    // mui uses enUS and not en-US
    const themeWithLocale = useMemo(() => createTheme(theme, locales[locale.replace("-", "")]), [locale]);

    return (
        <ThemeProvider theme={themeWithLocale}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <RequireAuth>
                                <MainLayout />
                            </RequireAuth>
                        }
                    >
                        <Route
                            path="/screenshots"
                            exact
                            element={
                                <RequireRight right={RIGHTS.screenshots}>
                                    <SubModuleNav />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/wrapper"
                            element={
                                <RequireRight right={RIGHTS.wrapper}>
                                    <SubModuleNav />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/administration"
                            exact
                            element={
                                <RequireRight right={RIGHTS.administration}>
                                    <SubModuleNav />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/administration/users"
                            exact
                            element={
                                <RequireRight right={RIGHTS.administration}>
                                    <UsersList />
                                </RequireRight>
                            }
                        />
                        <Route
                            path="/tools"
                            exact
                            element={
                                <RequireRight right={RIGHTS.tools}>
                                    <SubModuleNav />
                                </RequireRight>
                            }
                        />

                        <Route path="*" element={<NotFound />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </BrowserRouter>
            <Spinner />
            <ToastContainer closeButton={false} autoClose={3000} closeOnClick pauseOnFocusLoss={false} />
        </ThemeProvider>
    );
};

export default App;
