import React, { useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Icon from "@mui/material/Icon";

import { Typography, AppBar, Toolbar, Grid, Tabs, Tab } from "@mui/material";

const ModuleNav = ({ tabs, title, icon }) => {
    const location = useLocation();
    const { t } = useTranslation();

    const getCurrentTabFromLocation = () => {
        const idx = tabs.findIndex((tab) => location.pathname.substring(0, tab.path.length) === tab.path);
        return idx;
    };

    const [currentTab, setCurrentTab] = useState(getCurrentTabFromLocation());

    const changeTab = (event, newTab) => {
        setCurrentTab(newTab);
    };

    return (
        <div>
            <AppBar component="div" color="primary" position="static" elevation={0} sx={{ zIndex: 0, ml: 0 }}>
                <Toolbar>
                    <Grid container justifyContent="flex-start" sx={{ pb: 1.5, pt: 3 }} spacing={3}>
                        <Grid item>
                            <Typography color="inherit" variant="title" component="h1">
                                {icon} {t(title)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {tabs.length > 0 && (
                <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
                    <Tabs onChange={changeTab} value={currentTab} textColor="inherit">
                        {tabs.map(({ id, path }) => (
                            <Tab key={id} label={t(id)} component={Link} to={path} />
                        ))}
                    </Tabs>
                </AppBar>
            )}
        </div>
    );
};

export default ModuleNav;
