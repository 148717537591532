import { createApi } from "@reduxjs/toolkit/query/react";
import request from "../request";

// Define a service using a base URL and expected endpoints
// eslint-disable-next-line import/prefer-default-export
export const jadApi = createApi({
    reducerPath: "jadApi",
    baseQuery: (args, api) => request(args, api),
    endpoints: () => ({}),
});
